import { BaseModel } from 'shared/models/base.model';
import { DocumentStatus } from 'shared/enums/document-status.enum';

export class LoadDocument extends BaseModel {
  documentId: number;
  type: number;
  status: number;
  fileExtension: string;
  code: string;

  private stopSeqNum?: number;
  private bookSeqNum: number;
  private createdDate: string;

  get stopSequenceNumber(): number {
    return this.stopSeqNum;
  }
  set stopSequenceNumber(value: number) {
    this.stopSeqNum = value;
  }

  get bookSequenceNumber(): number {
    return this.bookSeqNum;
  }
  set bookSequenceNumber(value: number) {
    this.bookSeqNum = value;
  }

  get id(): number {
    return this.documentId;
  }

  set createdDateTime(date: Date) {
    this.createdDate = this.convertDateToDateString(date);
  }
  get createdDateTime(): Date {
    return this.processDate(this.createdDate);
  }

  constructor(json: DocumentJSON) {
    super(json);
  }

  isProcessingUpload() {
    return this.status === DocumentStatus.ProcessingUpload;
  }
  canDownload() {
    return (this.status > DocumentStatus.ProcessingUpload && this.status < DocumentStatus.Deleted) || this.status === DocumentStatus.Unknown ;
  }

  toJson(): DocumentJSON {
    return Object.assign({}, this) as any;
  }
}
