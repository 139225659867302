import { UUID } from 'angular2-uuid';

import * as v from 'app/forms/control/form-validators';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { FormGroupStructure, groupStructure } from 'app/forms/structure/form-group.structure';
import { DocumentType as DocType, DocumentType } from 'shared/enums/document-type.enum';
import { ExtendedLoad } from 'shared/models/loads/extended-load.model';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { DocumentJSON } from '@features/my-loads-v2/types';
export const ADD_DOCUMENT_FORM_KEY = 'ADD_DOCUMENT_FORM';

export interface DocumentFormValues {
  docType: DocumentType;
  stop: number;
  amount: number;
  invoiceNumber: string;
  invoiceAmount: string;
  lumperAmount: number;
  currency: number;
  fuelSurcharge: number;
}

export class AddDocumentForm extends NavCarrierFormGroup<DocumentFormValues> {
  constructor() {
    super(groupStructure({
      docType: fieldStructure(null, { validators: [v.required] }),
      stop: fieldStructure(),
      fileName: fieldStructure(null, { validators: [v.pattern(/.(jpg|jpeg|pdf|tif|tiff)$/i)] })
    }));
    this.get('stop').addValidator(v.requiredWhen(() => Number(this.get('docType').value) === DocumentType.Odometer));
    this.get('docType').valueChanges.subscribe(this.onDocTypeChange);
    this.validate();
  }

  onDocTypeChange = (value) => {
    this.get('stop').setPendingValidation();

    this.removeChild('invoiceNumber', true);
    this.removeChild('invoiceAmount', true);
    this.removeChild('lumperAmount', true);
    this.removeChild('currency', true);
    this.removeChild('fuelSurcharge', true);

    switch (value) {
      case DocType.Invoice:
        this.addChild('invoiceNumber', new NavCarrierFormControl(fieldStructure(null, { validators: [v.required] })), true);
        this.addChild('invoiceAmount', new NavCarrierFormControl(fieldStructure()), true);
        this.addChild('currency', new NavCarrierFormControl(fieldStructure()), true);
        this.addChild('fuelSurcharge', new NavCarrierFormControl(fieldStructure()), true);
        break;
      case DocType.Lumper:
        this.addChild('lumperAmount', new NavCarrierFormControl(fieldStructure()), true);
        this.addChild('currency', new NavCarrierFormControl(fieldStructure()), true);
        break;
    }
    this.rebuildValidators();
  };

  /** Performs conversion on valid string amount with commas used as dollar separator, transforming into decimal number. For example, convert '123,45' to '123.45'.
   * NOTE: This assumes that the comma is a dollar separator and not a thousand separator. Example '1,234' will be converted to '1.234'.
   * */
  convertToDecimalFormat(inputValue: string): number | null {
    if (!inputValue) {
      return null;
    }

    const cleanedAmount = inputValue.replace(',', '.');
    const floatValue = parseFloat(cleanedAmount);
    if (isNaN(floatValue)) {
      return null;
    }
    return floatValue;
  }

  generateFormData(file: File, load: ExtendedLoad): FormData {
    const formData = new FormData();
    const values = this.value;
    const documentJSON: DocumentJSON = {
      FileName: UUID.UUID(),
      LoadNumber: load.number,
      Type: Number(values.docType),
      CarrierCode: load.loadBook.carrier.code
    };

    if (values.stop != null && (values.stop as any) !== "") {
      documentJSON.StopSeqNum = Number(values.stop);
    }

    // This allows users to enter amount with decimal or comma but only the decimal format will be sent to the server.
    const invoiceAmount = this.convertToDecimalFormat(values.invoiceAmount);
    if (invoiceAmount) {
      documentJSON.Amount = invoiceAmount;
    }
    if (values.invoiceNumber != null) {
      documentJSON.InvoiceNumber = values.invoiceNumber;
    }
    if (values.lumperAmount != null) {
      documentJSON.Amount = Number(values.lumperAmount);
    }
    if (values.currency != null) {
      documentJSON.Currency = Number(values.currency);
    }
    if (values.fuelSurcharge != null) {
      documentJSON.FuelSurcharge = Number(values.fuelSurcharge);
    }

    formData.append('file', file, file.name);
    formData.append('DocumentJson', JSON.stringify(documentJSON));

    return formData;
  }
}

export const addDocumentFormStructure = (): FormGroupStructure =>
  groupStructure({
    docType: fieldStructure(null, { validators: [v.required] }),
    stop: fieldStructure(),
    fileName: fieldStructure(null, { validators: [v.pattern(/.(jpg|pdf|tif)$/)] })
  });

export const AddDocumentFieldStructure = {
  InvoiceAmount: fieldStructure(),
  InvoiceNumber: fieldStructure(null, { validators: [v.required] }),
  LumperAmount: fieldStructure(),
  Currency: fieldStructure(),
  FuelSurcharge: fieldStructure(),
};
