export enum DocumentType {
  Accessorial = 0,
  BOL = 1,
  Invoice = 2,
  Lumper = 3,
  POD = 4,
  WeightTicket = 5,
  Other = 6,
  Unspecified = 7,
  Odometer = 8,
  InternationalCMR = 9,
  InternationalPOD = 10,
  Thermoscript = 11,
  Pallets = 12,
  PackingList = 13,
  Customs = 14,
  CleaningCertificate = 15,
  CONFIRM = 16,     // Rate Confirmation
  PICKUP = 17,      // IMDL Pickup Order
  DELIVERY = 18,    // IMDL Delivery Prenote
  CARNOTIF = 19,    // Carrier Notification
  COMM_INV = 20,    // Intl Commercial Invoice
  CUST_OTHER = 21,  // Customer Other
  TENDER = 22,
  STATACCT = 23, // Statement of Account
  CREDIT = 24,
  CLAIM = 25,
  SACARINV = 26,
  CUSTRATEAG = 27,
  AWB = 28, // Air Way Bill
  TMC_OTHER = 33
}
