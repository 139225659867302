import { Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { DocumentCategory } from 'shared/enums/document-category.enum';
import { LoadDocument } from 'shared/models/load-document.model';
import { ExtendedLoad } from 'shared/models/loads/extended-load.model';
import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { Headers, MimeType } from 'app/globals/constants';

@Service()
export class DocumentRepository extends CacheableRepository {
  @Inject('apiConfig.documentsAPI')
  protected baseUrl;

  public getReferenceData(category?: string, forceRefresh?: boolean) {
    const params = this.generateQueryString({category});
    return this.getWithCache(`/ReferenceData${params}`, forceRefresh);
  }

  public getDocumentTypes(loadNumber: number): Observable<{[key: string]: number}> {
    const params = this.generateQueryString({
      loadNumber: loadNumber,
      category: 'DocumentType'
    });
    return this.getWithCache(`/ReferenceData${params}`, false);
  }

  public getDocuments(load: ExtendedLoad, categories: DocumentCategory[]): Observable<LoadDocument[]> {
    const queryString = categories.map(category => `category=${category}`).join('&');

    return this.get(`/Loads/${load.number}/Books/${load.loadBook.sequenceNumber}/Documents?${queryString}`)
      .map(docs => docs.map(doc => new LoadDocument(doc)));
  }

  // for my loads redesign purposes
  public getDocumentsV2(loadNumber: number, sequenceNumber: string, categories: DocumentCategory[]): Observable<LoadDocument[]> {
    
    const queryString = categories.map(category => `category=${category}`).join('&');

    return this.get(`/Loads/${loadNumber}/Books/${sequenceNumber}/Documents?${queryString}`)
      .map(docs => docs.map(doc => new LoadDocument(doc)));
  }

  public uploadDocument(load: ExtendedLoad, uploadData: FormData): Observable<void> {
    return this.postWithMultipart(`/Loads/${load.number}/Documents`, uploadData, {[Headers.CONTENT_TYPE]: 'multipart/form-data'});
  }

  public downloadDocument(load: ExtendedLoad, document: LoadDocument): Observable<Blob> {
    const mimeType = MimeType.getByExtension(document.fileExtension);

    return this.getBlob(`/Loads/${load.number}/Documents/${document.documentId}/File`, {[Headers.ACCEPT]: mimeType});
  }

  // for my loads redesign purposes
  public downloadDocumentV2(loadNumber: number, document: LoadDocument): Observable<Blob> {
    const mimeType = MimeType.getByExtension(document.fileExtension);

    return this.getBlob(`/Loads/${loadNumber}/Documents/${document.documentId}/File`, {[Headers.ACCEPT]: mimeType});
  }
  // new call for payments page using payableDocket as param
  public getDocumentsPayments(loadNumber: number, loadBookNumber: number, categories: DocumentCategory[]): Observable<LoadDocument[]> {
    const queryString = categories.map(category => `category=${category}`).join('&');

    return this.get(`/Loads/${loadNumber}/Books/${loadBookNumber}/Documents?${queryString}`)
      .map(docs => docs.map(doc => new LoadDocument(doc)));
  }

  public downloadDocumentPayments(loadNumber: number, document: LoadDocument): Observable<Blob> {
    const mimeType = MimeType.getByExtension(document.fileExtension);

    return this.getBlob(`/Loads/${loadNumber}/Documents/${document.documentId}/File`, { [Headers.ACCEPT]: mimeType });
  }
}
