export enum DocumentStatus {
  Unknown = 0,
  Deleted = 1300,
  DuplicateDocument = 1500,
  Error = -100,
  Indexed = 1000,
  ProcessingUpload = 400,
  ReadyForBucketing = 700,
  ReadyForIndexing = 900,
  ReadyForAutoIndexing = 675
}
